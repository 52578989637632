<template>
  <div>
    <a-form
      class="iotplt-nested-search-form"
      :form="form"
      @submit="handleSearch"
    >
      <a-row :gutter="24">
        <a-col :span="8" :xxl="6">
          <a-form-item>
            <a-input
              v-decorator="['iccid_or_phone_number_or_imsi', {
                normalize: this.$lodash.trim
              }]"
              placeholder="请输入ICCID号或手机号或IMSI"
              allow-clear
            >
              <span slot="addonAfter" @click="showBatchInputIccidsModal" class="iotplt-cursor-pointer">批量输入</span>
            </a-input>
          </a-form-item>
        </a-col>

        <a-col :span="8" :xxl="6" v-show="expand">
          <a-form-item label="运营商种类">
            <a-select
              v-decorator="['carrier_type']"
              allow-clear
            >
              <a-select-option
                v-for="option in carrierTypeOptions"
                :key="option.value"
                :value="option.value"
              >
                {{ option.label }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="8" :xxl="6" v-show="expand">
          <a-form-item label="生命周期">
            <a-select
              v-decorator="['life_cycle']"
              allow-clear
              @focus="fetchLifeCycleOptions"
              :loading="loadingLifeCycleOptions"
            >
              <a-select-option
                v-for="option in lifeCycleOptions"
                :key="option.slug"
                :value="option.slug"
              >
                {{ option.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="8" :xxl="6" v-show="expand">
          <a-form-item label="联网状态">
            <a-select
              v-decorator="['net_status']"
              allow-clear
              @focus="fetchNetStatusOptions"
              :loading="loadingNetStatusOptions"
            >
              <a-select-option
                v-for="option in netStatusOptions"
                :key="option.slug"
                :value="option.slug"
              >
                {{ option.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="8" :xxl="6" v-show="expand">
          <a-form-item label="用户业务模式">
            <a-select
              v-decorator="['user_business_type']"
              allow-clear
            >
              <a-select-option
                v-for="option in userBusinessTypeOptions"
                :key="option.value"
                :value="option.value"
              >
                {{ option.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="8" :xxl="6" v-show="expand">
          <a-form-item label="套餐">
            <a-select
              v-decorator="['product_id']"
              :loading="loadingProductionOptions"
              @focus="fetchProductionOptions"
              allow-clear
            >
              <a-select-option
                v-for="option in productionOptions"
                :key="option.product_id"
                :value="option.product_id"
              >
                {{ option.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="8" :xxl="6" v-show="expand">
          <a-form-item label="实名认证状态">
            <a-select
              v-decorator="['authentication_status']"
              allow-clear
            >
              <a-select-option key="1" value="1">
                已实名
              </a-select-option>
              <a-select-option key="0" value="0">
                未实名
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="8" :xxl="6" v-show="expand">
          <a-form-item label="订单编号">
            <a-input
              v-decorator="['order_no', {
                normalize: this.$lodash.trim
              }]"
              allow-clear
            />
          </a-form-item>
        </a-col>

        <a-col :span="8" :xxl="6" v-show="expand">
          <a-form-item label="划拨对象">
            <a-select
              v-decorator="['agency_id']"
              allow-clear
              @focus="fetchAgencyOptions"
              :loading="loadingAgencyOptions"
            >
              <a-select-option
                v-for="option in agencyOptions"
                :key="option.id"
                :value="option.id"
              >
                {{ option.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="8" :xxl="6" v-show="expand">
          <a-form-item label="卡号分组">
            <a-input
              v-decorator="['group_name', {
                normalize: this.$lodash.trim
              }]"
              allow-clear
            />
          </a-form-item>
        </a-col>

        <a-col :span="8" :xxl="6" v-show="expand">
          <a-form-item label="设备序列号">
            <a-input
              v-decorator="['device_sn', {
                normalize: this.$lodash.trim
              }]"
              allow-clear
            />
          </a-form-item>
        </a-col>

        <a-col :span="8" :xxl="6" v-show="expand">
          <a-form-item label="设备名称">
            <a-input
              v-decorator="['device_name', {
                normalize: this.$lodash.trim
              }]"
              allow-clear
            />
          </a-form-item>
        </a-col>

        <a-col :span="8" :xxl="6" v-show="expand">
          <a-form-item label="备注">
            <a-input
              v-decorator="['remark', {
                normalize: this.$lodash.trim
              }]"
              allow-clear
            />
          </a-form-item>
        </a-col>

        <a-col :span="8" :xxl="6">
          <a-form-item>
            <a-space>
              <a-button type="primary" html-type="submit">
                搜索
              </a-button>
              <a-button @click="handleReset">
                刷新
              </a-button>
              <a-button :style="{ fontSize: '12px', lineHeight: '32px' }" @click="toggle">
                更多条件
                <a-icon :type="expand ? 'up' : 'down'" />
              </a-button>
            </a-space>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>

    <!-- 批量验证模态框 -->
    <batch-input-iccids
      v-if="isShowBatchInputIccidsModal"
      :visible.sync="isShowBatchInputIccidsModal"
      :agent-id="agentId"
      @submit="submitBatchValidate"
    />
  </div>
</template>
<script>
import {
  findLifeCycleOptions,
  findUserBusinessTypeOptions,
  findCarrierTypeOptions,
  findNetStatusOptions
} from '@/api/common_options'
import { findAgentsProductsOptions } from '@/api/agents_product'
import { findEffectiveAgencyOptions } from '@/api/agent'

export default {
  name: 'SearchAgentSimCard',
  components: {
    BatchInputIccids: () => import('@/views/sim_cards/BatchInputIccids')
  },
  props: {
    agentId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      expand: false,
      carrierTypeOptions: findCarrierTypeOptions(),
      lifeCycleOptions: [],
      netStatusOptions: [],
      agencyOptions: [],
      productionOptions: [],
      userBusinessTypeOptions: findUserBusinessTypeOptions(),
      loadingLifeCycleOptions: false,
      loadingNetStatusOptions: false,
      loadingProductionOptions: false,
      loadingAgencyOptions: false,
      isShowBatchInputIccidsModal: false, // 是否显示批量输入卡号模态框
      form: this.$form.createForm(this, { name: 'sim_card_search' })
    }
  },
  methods: {
    toggle() {
      this.expand = !this.expand
    },

    showBatchInputIccidsModal() {
      this.isShowBatchInputIccidsModal = true
    },

    submitBatchValidate(iccids) {
      this.form.setFieldsValue({
        iccid_or_phone_number_or_imsi: iccids
      })
    },

    // 加载生命周期选项数据
    fetchLifeCycleOptions() {
      if (this.lifeCycleOptions.length === 0) {
        this.loadingLifeCycleOptions = true
        findLifeCycleOptions().then(res => {
          if (res.code === 0) {
            this.lifeCycleOptions = res.data
            this.loadingLifeCycleOptions = false
          }
        })
      }
    },

    // 加载联网状态选项数据
    fetchNetStatusOptions() {
      if (this.netStatusOptions.length === 0) {
        this.loadingNetStatusOptions = true
        findNetStatusOptions().then(res => {
          if (res.code === 0) {
            this.netStatusOptions = res.data
            this.loadingNetStatusOptions = false
          }
        })
      }
    },

    // 加载划拨对象选项数据
    fetchAgencyOptions() {
      if (this.productionOptions.length === 0) {
        this.loadingAgencyOptions = true
        findEffectiveAgencyOptions({ top_agent_id: this.agentId }).then((res) => {
          if (res.code === 0) {
            this.agencyOptions = res.data
            this.agencyOptions.push({
              id: 0,
              name: '未划拨'
            })
          }
          this.loadingAgencyOptions = false
        })
      }
    },

    // 加载套餐选项
    fetchProductionOptions() {
      if (this.productionOptions.length === 0) {
        this.loadingProductionOptions = true
        findAgentsProductsOptions({ agent_id: this.agentId }).then(res => {
          if (res.code === 0) {
            this.productionOptions = res.data
            this.loadingProductionOptions = false
          }
        })
      }
    },

    handleSearch(e) {
      e.preventDefault()
      this.$emit('submit', this.form.getFieldsValue())
    },

    handleReset() {
      this.form.resetFields()
      this.$emit('submit', {})
    }
  }
}
</script>
